
//公共库
import { Message, MessageBox } from "element-ui";
import { Component, Vue } from "vue-property-decorator";

//本地引入
import GFunc from "@/utils/gFunc";
import { countryList } from "@/utils";
import Pagination from "@/comp/Pagination/index.vue";
import { getShopPropconfList } from "@/api/request/item";
import { addShopMall, editShopMall, getShopMallList, setShopMallStatus } from "@/api/request/mall";

//组件
@Component({
  name: "MallDiamond",
  components: {
    Pagination,
  },
})

//界面函数
export default class extends Vue {
  //定义变量
  private list: any[] = [];
  private total: number = 0;
  private mallType: number = 1;
  private countryList: any[] = [];
  private propconfList: any[] = [];
  private listLoading: boolean = true;

  //请求参数
  private listQuery = {
    //页码数据
    page: 1,
    row: 20,

    //请求数据
    name: "",
    status: "",
    country: "",
    type: this.mallType,
  };

  //商品状态
  private productStatus: any[] = [
    { type: "", name: "全部" },
    { type: "0", name: "下架" },
    { type: "1", name: "上架" },
    { type: "2", name: "删除" },
  ];

  //商品状态
  private productStatusEx: any[] = [
    { type: 0, name: "下架" },
    { type: 1, name: "上架" },
    { type: 2, name: "删除" },
  ];

  //创建
  created() {
    //数据赋值
    this.countryList = countryList;

    //获取道具列表
    this.getShopPropconfList();

    //获取数据
    this.getList();
  }

  // //获取数据
  private async getList() {
    //显示等待
    this.listLoading = true;

    //获取数据
    const { data } = await getShopMallList(this.listQuery);

    //数据赋值
    this.list = data.list;
    this.total = data.total;

    //隐藏等待
    setTimeout(() => {
      this.listLoading = false;
    }, 0.5 * 1000);
  }

  //处理查询
  private handleFilter(): void {
    //数据赋值
    this.listQuery.page = 1;

    //获取列表
    this.getList();
  }

  //处理创建
  private handleCreate(): void {
    //数据赋值
    this.addForm = {
      //语言数据
      name_list: {
        zh: "",
        en: "",
        id: "",
      },

      //基本参数
      id: 0, //商品ID
      cnt: 0, //商品价格
      sort: 0, //排列顺序
      status: 0, //商品状态 0-下架 1-上架 2-删除
      type: this.mallType, //商品类型 1 钻石商城 2 互动币商城
      props: "", //道具集合 {1006:1,1002:2}
      country: "",
    };
    this.propData = [];
    this.dialogStatus = "create";

    //显示对话框
    this.dialogVisible = true;
  }

  //获取道具列表
  private async getShopPropconfList() {
    //请求数据
    const reqParams = {
      id: 0,
      page: 1,
      giftid: 0,
      model: 0,
      propname: "",
      giftname: "",
      row: GFunc.getListRowLimit(),
    };

    //显示等待
    this.listLoading = true;

    //获取数据
    const { data } = await getShopPropconfList(reqParams);

    //数据赋值
    this.propconfList = data.list;
    this.propconfList.unshift({ id: 0, propname: "请选择" });
  }

  //处理编辑
  private handleUpdate(row: any): void {
    //数据赋值
    this.propData = [];
    const props = row.props || "{}";
    const data = JSON.parse(props);
    const keys = Object.keys(data);
    for (let i = 0; i < keys.length; i++) {
      const prop: any = { id: 0, num: 0 };
      prop.id = GFunc.checkint(keys[i]);
      prop.num = GFunc.checkint(data[prop.id]);
      this.propData.push(prop);
    }
    this.dialogStatus = "update";
    this.addForm = GFunc.deepClone(Object.assign({}, row));

    //显示对话框
    this.dialogVisible = true;
  }

  //处理删除
  private handleDelete(row: any): void {
    //显示提示
    MessageBox.confirm("删除操作不可恢复，确定删除吗？", "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    })
      .then(() => {
        //处理删除操作
        this.opMallProducts(row);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //处理删除操作
  private async opMallProducts(row: any) {
    //数据赋值
    await setShopMallStatus({ id: row.id, status: 2 });

    //数据赋值
    const index = this.list.findIndex((v) => v.id === row.id);
    this.list.splice(index, 1);

    //显示提示
    this.$notify({
      title: "成功",
      message: "成功删除公告数据",
      type: "success",
      duration: 2000,
    });
  }

  //道具列表
  private getPropsList(props: any): any[] {
    //定义变量
    var propsList: any[] = [];

    //数据赋值
    for (var id in props) {
      //数据赋值
      var name: string = "";
      for (var i: number = 0; i < this.propconfList.length; i++) {
        if (Number(id) == this.propconfList[i].id) {
          name = this.propconfList[i].propname;
          break;
        }
      }
      propsList.push({ id: id, name: name, num: props[id] });
    }

    //返回结果
    return propsList;
  }

  //名称处理
  private changeToReal(value: any): string {
    return GFunc.checkstring(value) ? value : "--";
  }

  //获取状态名称
  private getProductStatusName(type: any): string {
    //定义变量
    let name: string = "--";

    //数据赋值
    type = GFunc.checkstring(type);
    for (let i = 0; i < this.productStatus.length; i++) {
      const pro = this.productStatus[i];
      if (type === pro.type) {
        name = pro.name;
        break;
      }
    }

    //返回结果
    return name;
  }

  //获取归属国家名称
  private getCountryName(country: string): string {
    //没有国家属性
    if (country == "") {
      return "-";
    }

    //判断国家
    for (var i: number = 0; i < this.countryList.length; i++) {
      if (country == this.countryList[i].country) {
        return this.countryList[i].name;
      }
    }

    //反馈空
    return "";
  }

  //--------------------------------- 添加/编辑 ---------------------------------
  //定义变量
  private propData: any[] = [];
  private dialogStatus: string = "";
  private dialogVisible: boolean = false;
  private titleMap = { update: "编辑商品", create: "新增商品" };

  //添加表单
  private addForm: any = {
    //语言数据
    name_list: {
      zh: "",
      en: "",
      id: "",
    },

    //基本参数
    id: 0, //商品ID
    cnt: 0, //商品价格
    sort: 0, //排列顺序
    status: 0, //商品状态 0-下架 1-上架 2-删除
    type: this.mallType, //商品类型 1 钻石商城 2 互动币商城
    props: "", //道具集合 {1006:1,1002:2}
    country: "",
  };

  //增加商品道具
  private addProp(): void {
    //数据赋值
    this.propData.push({ id: 0, num: 0 });

    //滚动到最下面
    this.$nextTick(() => {
      (this.$refs.cList as any).scrollTop = (this.$refs.cList as any).scrollHeight;
    });
  }

  //创建商品
  private async createData() {
    //数据赋值
    var props: any = {};
    var propData: any[] = [];
    for (var i: number = 0; i < this.propData.length; i++) {
      if (this.propData[i].id != 0 && Number(this.propData[i].num) != 0) {
        propData.push(this.propData[i]);
        props[this.propData[i].id] = Number(this.propData[i].num);
      }
    }

    //判断返回
    if (this.addForm.name_list.zh == "") return Message({ message: "请输入道具名称(中文)！", type: "error", duration: 5 * 1000 });
    if (this.addForm.name_list.en == "") return Message({ message: "请输入道具名称(英文)！", type: "error", duration: 5 * 1000 });
    if (this.addForm.name_list.id == "") return Message({ message: "请输入道具名称(印尼)！", type: "error", duration: 5 * 1000 });
    if (Number(this.addForm.cnt) == 0) return Message({ message: "请输入商品价格！", type: "error", duration: 5 * 1000 });
    if (propData.length == 0) return Message({ message: "请设置商品道具！", type: "error", duration: 5 * 1000 });

    //数据赋值
    this.addForm.props = JSON.stringify(props);
    this.addForm.cnt = GFunc.checkint(this.addForm.cnt);
    this.addForm.sort = GFunc.checknumber(this.addForm.sort);
    this.addForm.type = GFunc.checkint(this.mallType);

    //添加
    await addShopMall(this.addForm);

    //隐藏对话框
    this.dialogVisible = false;

    //获取列表
    this.getList();

    //显示提示
    this.$notify({
      title: "成功",
      message: "创建成功",
      type: "success",
      duration: 2000,
    });
  }

  //更新商品
  private async updateData() {
    //数据赋值
    var props: any = {};
    var propData: any[] = [];
    for (var i: number = 0; i < this.propData.length; i++) {
      if (this.propData[i].id != 0 && Number(this.propData[i].num) != 0) {
        propData.push(this.propData[i]);
        props[this.propData[i].id] = Number(this.propData[i].num);
      }
    }

    //判断返回
    if (this.addForm.name_list.zh == "") return Message({ message: "请输入道具名称(中文)！", type: "error", duration: 5 * 1000 });
    if (this.addForm.name_list.en == "") return Message({ message: "请输入道具名称(英文)！", type: "error", duration: 5 * 1000 });
    if (this.addForm.name_list.id == "") return Message({ message: "请输入道具名称(印尼)！", type: "error", duration: 5 * 1000 });
    if (Number(this.addForm.cnt) == 0) return Message({ message: "请输入商品价格！", type: "error", duration: 5 * 1000 });
    if (propData.length == 0) return Message({ message: "请设置商品道具！", type: "error", duration: 5 * 1000 });

    //数据赋值
    this.addForm.props = JSON.stringify(props);
    this.addForm.cnt = GFunc.checkint(this.addForm.cnt);
    this.addForm.sort = GFunc.checknumber(this.addForm.sort);
    this.addForm.type = GFunc.checkint(this.mallType);

    //添加
    await editShopMall(this.addForm);

    //隐藏对话框
    this.dialogVisible = false;

    //获取列表
    this.getList();

    //显示提示
    this.$notify({
      title: "成功",
      message: "更新成功",
      type: "success",
      duration: 2000,
    });
  }

  //是否创建
  private isCreate(): boolean {
    return this.dialogStatus === "create";
  }

  //删除道具
  private removeProp(item: any): void {
    //显示提示
    MessageBox.confirm("确定删除该道具吗？", "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    })
      .then(() => {
        //数据赋值
        const index = this.propData.indexOf(item);
        if (index !== -1) this.propData.splice(index, 1);
      })
      .catch((err) => {
        console.log(err);
      });
  }
}
