import axios from "axios";

//本地导入
import request, { getAppBaseApi, getHeaders } from "../request";

//商城列表
export const getShopMallList = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/shop/mall/list",
  });

//商城上架下架删除
export const setShopMallStatus = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/shop/mall/status",
  });

//商城添加
export const addShopMall = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/shop/mall/add",
  });

//商城编辑
export const editShopMall = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/shop/mall/edit",
  });
